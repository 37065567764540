import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

export async function createExcelFile (dataToExportArray, fileName) {
  const workbook = new ExcelJS.Workbook()

  // eslint-disable-next-line no-restricted-syntax
  for (const { dataToExport, config } of dataToExportArray) {
    const sheet = workbook.addWorksheet(config.sheetName, { views: config.view })
    sheet.columns = config.columns
    sheet.addRows(dataToExport)
  }

  // Write workbook file
  const data = await workbook.xlsx.writeBuffer()
  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  FileSaver.saveAs(blob, `${fileName}.xlsx`)
}
